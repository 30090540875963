import Input from '@mui/joy/Input';
import React from 'react'

const InputField = ({...otherprops}) => {
  return (
    <Input  {...otherprops} sx={{
        color: 'white',
        bgcolor: '#050913',
        border: '1px solid #909090',
        width: {xs:'21.5rem', lg: '100%'},
        p: '1rem'

    }}></Input>
  )
}

export default InputField