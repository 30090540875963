import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Icon,
  IconButton,
  Input,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import React, { useState } from "react";
import Header from "./common/Header";
import TextArea from "./common/TextArea";
import CustomButton from "./common/CustomButton";
import { VscDebugAll } from "react-icons/vsc";
import { FaFileCircleCheck } from "react-icons/fa6";
import { BsClipboard2DataFill } from "react-icons/bs";
import bg from "../assets/bg.jpg";
import { VscDebugRerun } from "react-icons/vsc";
import { VscBookmark } from "react-icons/vsc";
import { TbArrowBigRightLinesFilled } from "react-icons/tb";
import { TbArrowBigDownLinesFilled } from "react-icons/tb";
import { FaRobot } from "react-icons/fa";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import InputField from "./common/InputField";
import Container from "./common/Container";
import PromptArea from "./common/PromptArea";
import OpenAI from "openai";
import { OPENAI_API_KEY } from "../constant/constant";
import { toast } from "react-toastify";
import { FaGlobe } from "react-icons/fa";
import { RiPassValidLine } from "react-icons/ri";
import { IoServerSharp } from "react-icons/io5";
import { LuCodepen } from "react-icons/lu";
import { GrValidate } from "react-icons/gr";

const openAi = new OpenAI({
  apiKey: OPENAI_API_KEY,
  dangerouslyAllowBrowser: true,
});

const Body = () => {
  const [inputValue, setInputValue] = useState("");
  const [outputValue, setOutputValue] = useState("");
  const [aiSuggestionValue, setAiSuggestionValue] = useState("");
  const [selected, setSelected] = useState("Code AI");
  const [loading, setLoading] = useState(false);
  const [purpose, setPurpose] = useState("");
  const [language, setLanguage] = useState("");
  const [chanllenge, setChallenge] = useState("");
  const [expected, setExpected] = useState("");
  const [actual, setActual] = useState("");
  const [currentNav, setCurrentNav] = useState(selected);

  const handleChange = (event, newSelected) => {
    setLanguage("");
    setOutputValue("");
    setInputValue("");
    setAiSuggestionValue("");
    setSelected(newSelected);

    setCurrentNav(newSelected);
  };

  // const handleIconClick = () => {
  //   setLoading(true);
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 3000);
  // };

  const handleIconClick = async () => {
    setLoading(true);
    try {
      let res1, res2;
      if (selected === "Code Reporter AI") {
        // debug
        let res = await reponseDebugger(inputValue, language, expected, actual);
        setOutputValue(res.content);
      } else if (selected === "Code AI") {
        let res = await codeHelper(inputValue, purpose, language, chanllenge);
        // console.log(res.content)
        setOutputValue(res.content);
      } else if (selected === "Teacher AI") {
        let res = await teacherHelper(inputValue, language);
        setOutputValue(res.content);
      } else if (selected === "Editor AI") {
        res1 = await codeCompiler(inputValue, language);
        res2 = await codeFixer(inputValue, language);
        setOutputValue(res1.content);
        setAiSuggestionValue(res2.content);
      }
      setLoading(false);
    } catch (e) {
      alert("Try again");
      setLoading(false);
    }
  };

  const reponseDebugger = async (code, language, output, ActualOutput) => {
    const responseGPT = await openAi.chat.completions.create({
      model: "gpt-3.5-turbo",
      temperature: 0,
      max_tokens: 1000,
      messages: [
        {
          role: "user",
          content: `"I am working on debugging a piece of code and need your assistance. Here are the details:
              1. ${language} 
              2. ${code}
              3.  Expected Output : ${output}
              4.  Actual Output : ${ActualOutput}
              
              Based on this information, can you provide insights into what might be causing the issue and suggest potential solutions or debug the code?"
              `,
        },
        {
          role: "user",
          content: code,
        },
      ],
    });

    return responseGPT.choices[0].message;
  };
  const codeHelper = async (code, purpose, language, challenges) => {
    const responseGPT = await openAi.chat.completions.create({
      model: "gpt-3.5-turbo",
      temperature: 0,
      max_tokens: 1000,
      messages: [
        {
          role: "system",
          content: `You are a helpful assistant that specializes in correcting and improving code.
    - When users provide details about their coding tasks, including the purpose, language, and challenges, offer relevant guidance, suggest solutions, or provide code examples.
    - Review the code snippets provided by users, identify any errors or issues, and offer corrections or improvements.
    - Explain each correction or suggestion clearly to help users understand how to resolve issues and improve their coding skills.
    - Focus on clarity and simplicity; avoid using technical jargon unless necessary for understanding.
    - Be supportive and encouraging, offering constructive feedback to help users learn and grow.
    - Use formatting to enhance readability: *text* for bold, _text_ for italics, '-' for bullet points.
    - If the code or details provided are incomplete or unclear, ask the user for clarification before proceeding.
    - Provide optimized and efficient solutions, adhering to best practices for the specific programming language.
    - Avoid engaging in off-topic conversations; remain focused on code correction and improvement tasks.`,
        },
        {
          role: "user",
          content: `I need assistance with a coding task. Here are the details of my request:
      - *Purpose*: ${purpose}
      - *Programming Language*: ${language}
      - *Challenges Faced*: ${challenges}
      
      Based on this information, can you provide guidance, suggest solutions, or offer code examples to help me with my coding task? Below is the code I'm working on:

      \`\`\`
      ${code}
      \`\`\`

      Please review the code and let me know how I can improve it or fix any issues.`,
        },
      ],
    });

    return responseGPT.choices[0].message;
  };

  const teacherHelper = async (code, language) => {
    const responseGPT = await openAi.chat.completions.create({
      model: "gpt-3.5-turbo",
      temperature: 0,
      max_tokens: 1000,
      messages: [
        {
          role: "system",
          content: `You are 'Teacher AI', a friendly and knowledgeable coding assistant.
    - Your name is Teacher AI, and you are here to help users learn how to code in various programming languages, including Python, JavaScript, Java, C++, and more.
    - You are multilingual and can explain coding concepts in the user's preferred language.
    - You provide step-by-step guidance, code examples, explanations of coding concepts, and best practices.
    - **ALWAYS** think step by step before returning a response.
    - You encourage users to ask questions and seek clarification when they don't understand something.
    - Keep responses concise, clear, and user-friendly; avoid overly technical jargon unless necessary for understanding.
    - Provide real-world examples and analogies to make coding concepts easier to grasp.
    - Use formatting to enhance readability: *text* for bold, _text_ for italics, '-' for bullet points.
    - Avoid unnecessary off-topic conversations; focus on helping users with coding-related queries.
    - If a topic is outside the scope of coding education, politely redirect the user back to coding-related topics.
    - Be patient and supportive, offering positive reinforcement to encourage continuous learning.`,
        },
        {
          role: "user",
          content: `I need assistance with a coding task. Here are the details of my request:
  I have a question about ${language}: ${inputValue} 
      
   Could you please provide a detailed explanation, including examples if necessary, to help me understand ${inputValue} better? only related to the ${language} so you are giving me ${code} strictly based on the ${language}

      \`\`\`
      ${code}
      \`\`\`

      Please review the code and let me know how I can improve it or fix any issues.`,
        },
      ],
    });

    return responseGPT.choices[0].message;
  };

  const codeCompiler = async (code, language) => {
    const responseGPT = await openAi.chat.completions.create({
      model: "gpt-3.5-turbo",
      temperature: 0,
      max_tokens: 1000,
      messages: [
        {
          role: "system",
          content: `You are a helpful assistant that compiles code from one programming language to another.
    - You specialize in translating code between various programming languages, such as Python, JavaScript, Java, C++, and more.
    - You provide accurate translations of code snippets, ensuring that syntax and functionality are preserved across different languages.
    - **ALWAYS** think step by step before providing a translated code snippet.
    - You clarify any ambiguities in code before proceeding with translation and ask for additional details if the code provided is incomplete or unclear.
    - Provide concise explanations of any changes made during the translation process to help users understand how the code functions in the target language.
    - Ensure the translated code is optimized for readability and follows best practices for the target programming language.
    - Use formatting to enhance readability: *text* for bold, _text_ for italics, '-' for bullet points.
    - Stay focused on the task of code translation; avoid engaging in unrelated conversations or extended chat.
    - Offer additional tips or suggestions to improve code quality when relevant.
    - Be patient and provide supportive feedback to encourage users to learn from the translations provided.`,
        },
        {
          role: "user",
          content: `I need assistance with a coding task. Here are the details of my request:
 Compile the following code ${inputValue} to a code in this ${language} and provide the output and show just the output nothing more

      \`\`\`
      ${code}
      \`\`\`

      Please review the code and let me know how I can improve it or fix any issues.`,
        },
      ],
    });

    return responseGPT.choices[0].message;
  };

  const codeFixer = async (code, language) => {
 
    const responseGPT = await openAi.chat.completions.create({
      model: "gpt-3.5-turbo",
      temperature: 0,
      max_tokens: 1000,
      messages: [
        {
          role: "system",
          content: `You are a helpful assistant that corrects code.
    - Your primary task is to review and correct code snippets provided by users, identifying errors and suggesting improvements.
    - You work with various programming languages, such as Python, JavaScript, Java, C++, and more.
    - **ALWAYS** think step by step before providing corrections or suggestions.
    - Provide clear explanations for each correction to help users understand what went wrong and how to fix it.
    - Offer optimized and efficient solutions while adhering to best practices for the specific programming language.
    - Use formatting to enhance readability: *text* for bold, _text_ for italics, '-' for bullet points.
    - Avoid using technical jargon unless necessary; keep explanations simple and user-friendly.
    - Ask for clarification if the code snippet is incomplete or unclear before making corrections.
    - Be supportive and encouraging, providing constructive feedback to help users improve their coding skills.
    - Focus on code correction tasks; avoid engaging in off-topic conversations.
    - Offer tips or suggestions for improving code quality, readability, and performance when relevant.`,
        },
        {
          role: "user",
          content: `I need assistance with a coding task. Here are the details of my request:
You will be provided with a piece of  code ${inputValue} in this language ${language}, and your task is to provide ideas for efficiency improvements based on the ${inputValue} and language ${language} provided ${code} all your output should be based on the ${language} provided.

      \`\`\`
      ${code}
      \`\`\`

      Please review the code and let me know how I can improve it or fix any issues.`,
        },
      ],
    });

    return responseGPT.choices[0].message;
  };

  const notify = () => {
    setSelected("Code Reporter AI");
    toast("Coming Soon!", { theme: "dark" });
  };

  return (
    <Box sx={{ p: "2rem 1rem" }}>
      <Header currentNav={selected} />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: { xs: "column", lg: "row" },
        }}
      >
        <ToggleButtonGroup
          color="secondary"
          value={selected}
          exclusive
          onChange={handleChange}
          aria-label="Platform"
          sx={{
            display: "flex",
            flexDirection: { lg: "column" },
            overflowX: { xs: "scroll" },
            scale: "2",
            p: { xs: "1rem 0", lg: "0" },
          }}
        >
          <Tippy placement="right" content="Code AI">
            <ToggleButton
              sx={{
                "&.Mui-selected": {
                  background:
                    "linear-gradient(315deg, #7F00FF 35%, #E100FF 50%)",
                  color: "#fff",
                },
                color: "#909090",
              }}
              value="Code AI"
            >
              {" "}
              <FaRobot style={{ height: "10px", width: "10px" }} />
            </ToggleButton>
          </Tippy>
          <Tippy placement="right" content="Code Reporter AI">
            <ToggleButton
              sx={{
                "&.Mui-selected": {
                  background:
                    "linear-gradient(315deg, #7F00FF 35%, #E100FF 50%)",
                  color: "#fff",
                },
                color: "#909090",
              }}
              value="Code Reporter AI"
            >
              <VscDebugAll style={{ height: "10px", width: "10px" }} />
            </ToggleButton>
          </Tippy>
          <Tippy placement="right" content="Develop AI">
            <ToggleButton
              onClick={notify}
              sx={{
                "&.Mui-selected": {
                  background:
                    "linear-gradient(315deg, #7F00FF 35%, #E100FF 50%)",
                  color: "#fff",
                },
                color: "#909090",
              }}
              // value="check"
            >
              <FaGlobe style={{ height: "10px", width: "10px" }} />
            </ToggleButton>
          </Tippy>
          <Tippy placement="right" content="Validation AI">
            <ToggleButton
              onClick={notify}
              sx={{
                "&.Mui-selected": {
                  background:
                    "linear-gradient(315deg, #7F00FF 35%, #E100FF 50%)",
                  color: "#fff",
                },
                color: "#909090",
              }}
              // value="explanation"
            >
              <GrValidate style={{ height: "10px", width: "10px" }} />
            </ToggleButton>
          </Tippy>
          <Tippy placement="right" content="Explanation AI">
            <ToggleButton
              onClick={notify}
              sx={{
                "&.Mui-selected": {
                  background:
                    "linear-gradient(315deg, #7F00FF 35%, #E100FF 50%)",
                  color: "#fff",
                },
                color: "#909090",
              }}
              // value="explanation"
            >
              <BsClipboard2DataFill style={{ height: "10px", width: "10px" }} />
            </ToggleButton>
          </Tippy>
          <Tippy placement="right" content="Static Hosting AI">
            <ToggleButton
              onClick={notify}
              sx={{
                "&.Mui-selected": {
                  background:
                    "linear-gradient(315deg, #7F00FF 35%, #E100FF 50%)",
                  color: "#fff",
                },
                color: "#909090",
              }}
              // value="explanation"
            >
              <IoServerSharp style={{ height: "10px", width: "10px" }} />
            </ToggleButton>
          </Tippy>
          <Tippy placement="right" content="Editor AI">
            <ToggleButton
              sx={{
                "&.Mui-selected": {
                  background:
                    "linear-gradient(315deg, #7F00FF 35%, #E100FF 50%)",
                  color: "#fff",
                },
                color: "#909090",
              }}
              value="Editor AI"
            >
              <LuCodepen style={{ height: "10px", width: "10px" }} />
            </ToggleButton>
          </Tippy>
          <Tippy placement="right" content="Teacher AI">
            <ToggleButton
              sx={{
                "&.Mui-selected": {
                  background:
                    "linear-gradient(315deg, #7F00FF 35%, #E100FF 50%)",
                  color: "#fff",
                },
                color: "#909090",
              }}
              value="Teacher AI"
            >
              <LuCodepen style={{ height: "10px", width: "10px" }} />
            </ToggleButton>
          </Tippy>
        </ToggleButtonGroup>
        <Container>
          {selected !== "Code AI" &&
            selected !== "Editor AI" &&
            selected !== "Teacher AI" && (
              <>
                <TextArea
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  placeholder="Enter Input"
                />
              </>
            )}
          {selected === "Code AI" && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "2rem",
              }}
            >
              <PromptArea
                placeholder="Enter the challenges"
                onChange={(e) => setChallenge(e.target.value)}
                value={chanllenge}
              />
              <PromptArea
                placeholder="Enter the purpose"
                onChange={(e) => setPurpose(e.target.value)}
                value={purpose}
              />
            </Box>
          )}
          {selected === "Teacher AI" && (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                }}
              >
                <label htmlFor="output">ASK QUESTION:</label>
                <TextArea
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  placeholder="Ask Question"
                />
              </Box>
            </>
          )}
          {selected === "Editor AI" && (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                }}
              >
                <label htmlFor="output">ENTER CODE:</label>
                <TextArea
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  placeholder="Enter Code"
                />
              </Box>
            </>
          )}
          <Box
            sx={{
              p: "2rem",
              display: "flex",
              gap: "2rem",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {selected === "Code Reporter AI" && (
              <>
                <InputField
                  placeholder="Language Used"
                  onChange={(e) => setLanguage(e.target.value)}
                  value={language}
                />
                <InputField
                  placeholder="Expected Output"
                  onChange={(e) => setExpected(e.target.value)}
                  value={expected}
                />
                <InputField
                  placeholder="Actual Output"
                  onChange={(e) => setActual(e.target.value)}
                  value={actual}
                />
              </>
            )}
            {selected === "Teacher AI" && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.5rem",
                  }}
                >
                  <label htmlFor="output">LANGUAGE:</label>
                  <InputField
                    placeholder="Language"
                    onChange={(e) => setLanguage(e.target.value)}
                    value={language}
                  />
                </Box>
              </>
            )}
            {selected === "Editor AI" && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.5rem",
                  }}
                >
                  <label htmlFor="output">LANGUAGE:</label>
                  <InputField
                    placeholder="Compile Language"
                    onChange={(e) => setLanguage(e.target.value)}
                    value={language}
                  />
                </Box>
              </>
            )}
            {selected === "Code AI" && (
              <>
                <InputField
                  placeholder="Enter the language"
                  onChange={(e) => setLanguage(e.target.value)}
                  value={language}
                />
              </>
            )}
            {loading ? (
              <Box sx={{ display: { xs: "none", lg: "block" } }}>
                <CircularProgress color="secondary" />
              </Box>
            ) : (
              <Box
                sx={{
                  display: { xs: "none", lg: "block" },
                  ":hover": {
                    color: "#E100FF",
                  },
                }}
              >
                <TbArrowBigRightLinesFilled
                  onClick={handleIconClick}
                  style={{ height: "4rem", width: "4rem" }}
                />
              </Box>
            )}

            {loading ? (
              <Box sx={{ display: { xs: "block", lg: "none" } }}>
                <CircularProgress color="secondary" />
              </Box>
            ) : (
              <Box
                sx={{
                  display: { xs: "block", lg: "none" },
                  ":hover": {
                    color: "#E100FF",
                  },
                }}
              >
                <TbArrowBigDownLinesFilled
                  onClick={handleIconClick}
                  style={{ height: "4rem", width: "4rem" }}
                />
              </Box>
            )}
          </Box>

          {selected !== "Editor AI" && (
            <TextArea
              onChange={(e) => setOutputValue(e.target.value)}
              value={outputValue}
              placeholder="Your Output"
            />
          )}
          {selected === "Editor AI" && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "2rem",
              }}
            >
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}
              >
                <label htmlFor="output">OUTPUT:</label>
                <PromptArea
                  id="output"
                  onChange={(e) => setOutputValue(e.target.value)}
                  value={outputValue}
                  placeholder="Your Output"
                  rows={6}
                />
              </Box>
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}
              >
                <label htmlFor="output">AI CODE SUGGESTION:</label>
                <PromptArea
                  onChange={(e) => setAiSuggestionValue(e.target.value)}
                  value={aiSuggestionValue}
                  placeholder="AI Code Suggestion"
                />
              </Box>
            </Box>
          )}
        </Container>
      </Box>
    </Box>
  );
};

export default Body;
