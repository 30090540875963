import { Box } from '@mui/material'
import React from 'react'

const TextArea = ({children, ...otherprops}) => {
  return (
    <Box sx={{
        // width: {xs: '16rem', lg: '22.3rem'}
    }}>
         <textarea
         {...otherprops}
        style={{
          backgroundColor: 'rgba(5, 9, 19, 0.8)',
            borderRadius: '10px',
            padding: '10px',
            height: '25rem',
            width: '20rem',
            color: 'white',
            minWidth: '10rem',
            resize: 'none'
           
        }}
      />
    </Box>
  )
}

export default TextArea