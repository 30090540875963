import { Box } from "@mui/material";
import React from "react";
import { IoArrowBackSharp } from "react-icons/io5";



const Header = ({currentNav}) => {
  return (
    <Box
      sx={{
        fontSize: {xs: '24px', md:"38px"},
        textAlign: "center",
        fontWeight: "bolder",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        p:{xs:"1rem 0", sm: '0rem 1rem 0 0'}
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'left', textTransform: 'capitalize', fontSize: {xs: '20px', md: '32px'}, width: '17rem', display: 'inline-block', textAlign: 'left'}}>
        {currentNav}
      </Box>
     

      <Box sx={{ display: "flex", gap: "1rem", width: '14rem', justifyContent: 'right' }} className="grd-color-1">InnovateAI</Box>
    </Box>
  );
};

export default Header;
