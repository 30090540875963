import { Box } from '@mui/material'
import React from 'react'

import bg from "../../assets/bg.jpg";

const Container = ({children}) => {
  return (
    <Box sx={{
        width: "95%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: { xs: "column", lg: "row" },
        height: { lg: "90vh" },
        gap: "2rem",
        boxShadow: "0px 0px 30px 1px #050505 inset",
        borderRadius: "10px",
        m: "1rem",
        p: "3rem 1rem",
        backgroundImage: `url(${bg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        position: "relative",
      }}>
        {children}
    </Box>
  )
}

export default Container