import { InputAdornment, TextareaAutosize } from "@mui/material";
import React from "react";

const PromptArea = ({ ...otherprops }) => {
  return (
    <textarea
      style={{
        backgroundColor: "rgba(5, 9, 19, 0.8)",
        borderRadius: "10px",
        padding: "10px",
        height: "7rem",
        width: "20rem",
        color: "white",
        minWidth: "10rem",
        resize: 'none'
      }}
      {...otherprops}
    />
  );
};

export default PromptArea;
